import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";

import Loading from "app/components/loading/Loading";
import Button from "ui/components/button/Button";
import useOutsideClick from "hooks/useOutsideClick";
import Plus from "ui/icons/plus/Plus";
import SquaresIcon from "ui/icons/squaresIcon/SquaresIcon";
import styles from "./AllEvents.module.scss";
import ArrowDown from "ui/icons/arrow-down/ArrowDown";
import { handleGetAllEvents } from "events/controllers";
import EventItem from "events/components/eventItem/EventItem";
import Check from "ui/icons/check/Check";
import ArrowRight from "ui/icons/arrow-right/ArrowRight";
import ToggleIcon from "ui/icons/ToggleIcon";
import Search from "ui/icons/search/Search";
import { errorsAPI } from "app/constants/errors";
import { showModal } from "app/actions/modal";
import { useDispatch } from "react-redux";
import Calendar from "ui/icons/calendar/Calendar";
import AllEventsDatepicker from "events/components/allEventsDatepicker/AllEventsDatepicker";
import moment from "moment";
import { XCircle } from "phosphor-react";

const AllEvents = () => {
  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const dateFilterRef = useRef(null);
  const dateCreatedFilterRef = useRef(null);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [dateFilterOpen, setDateFilterOpen] = useState<boolean>(false);
  const [dateCreatedFilterOpen, setDateCreatedFilterOpen] =
    useState<boolean>(false);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [search, setSearch] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [page, setPage] = useState(1);
  const [activeState, setActiveState] = useState("all");
  const [filterStatus, setFiltersStatus] = useState(null);
  const [limit, setLimit] = useState(24);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [createdStartDate, setCreatedStartDate] = useState<Date | null>(null);
  const [createdEndDate, setCreatedEndDate] = useState<Date | null>(null);

  const onClickOutsideFilter = useCallback(() => {
    setFilterOpen(false);
    setFiltersStatus(null);
  }, []);

  const onClickOutsideDateFilter = useCallback(() => {
    setDateFilterOpen(false);
  }, []);

  const onClickOutsideDateCreatedFilter = useCallback(() => {
    setDateCreatedFilterOpen(false);
  }, []);

  useOutsideClick(dateCreatedFilterRef, onClickOutsideDateCreatedFilter);
  useOutsideClick(filterRef, onClickOutsideFilter);
  useOutsideClick(dateFilterRef, onClickOutsideDateFilter);

  useEffect(() => {
    const filtersFinal = {
      limit: limit,
      page: page,
      filterByStyles: [],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: null,
    };
    handleGetAllEvents(filtersFinal)
      .then(function (response: any) {
        setEvents(response.data);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
      });
  }, []);

  function openFilter() {
    setFilterOpen(true);
  }

  const openDateFilter = () => {
    setDateFilterOpen(true);
  };

  const openDateCreatedFilter = () => {
    setDateCreatedFilterOpen(true);
  };

  const resetDateCreatedFilter = () => {
    setCreatedStartDate(null);
    setCreatedEndDate(null);
    setPage(1);
  };

  function getSearch(e) {
    const value = e.target.value;
    setSearch(value);
    setPage(1);
    setLoadingSearch(true);
  }

  function handleMore() {
    setPage(page + 1);
  }

  useEffect(() => {
    setLoading(true);
    let needValidation, active;
    switch (activeState) {
      case "all":
        needValidation = null;
        active = null;
        break;
      case "actives":
        needValidation = null;
        active = 1;
        break;
      case "inactives":
        needValidation = null;
        active = 0;
        break;
      case "pending":
        needValidation = true;
        active = 0;
        break;
    }
    const filtersFinal = {
      search: search,
      active,
      needValidation,
      limit: limit,
      page: page,
      startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      createdStartDate,
      createdEndDate,
    };

    handleGetAllEvents(filtersFinal)
      .then(function (response: any) {
        if (page > 1) {
          setEvents((events) => [...events, ...response.data]);
        } else {
          setEvents(response.data);
        }
        if (response.data.length < limit) {
          setShowMore(false);
        } else {
          setShowMore(true);
        }
        setLoadingSearch(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoadingSearch(false);
        setLoading(false);
        const data = {
          status: true,
          message: "aie",
          error: true,
        };
      });
  }, [
    search,
    page,
    activeState,
    startDate,
    endDate,
    limit,
    createdStartDate,
    createdEndDate,
  ]);

  function selectStatus(status) {
    setFiltersStatus(status);
  }

  const resetDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setPage(1);
  };

  const renderEvents = useMemo(() => {
    if (events && events.length === 0 && !loading)
      return (
        <div className={styles.empty}>
          <SquaresIcon />
          <p>Aucun résultat disponible</p>
        </div>
      );
    return events.map((event, index) => {
      return (
        <div key={index} className={styles.item}>
          <Link to={`/evenements/${event.id}`}>
            <EventItem event={event} />
          </Link>
        </div>
      );
    });
  }, [events]);

  const renderFilters = useMemo(() => {
    if (filterStatus === null) {
      return null;
    } else {
      return (
        <div className={styles.filterSecond}>
          <ul>
            <li>
              <div className={styles.checkBoxItem}>
                <input
                  type="radio"
                  name="active"
                  value="all"
                  checked={activeState === "all"}
                  onChange={() => {
                    setActiveState("all");
                  }}
                />
                <div className={styles.checkmark}>
                  <Check className={styles.check_icon} />
                </div>
                <p className={styles.checkLabel}>Tout afficher</p>
              </div>
            </li>
            <li>
              <div className={styles.checkBoxItem}>
                <input
                  type="radio"
                  name="active"
                  value="actives"
                  checked={activeState === "actives"}
                  onChange={() => {
                    setActiveState("actives");
                  }}
                />
                <div className={styles.checkmark}>
                  <Check className={styles.check_icon} />
                </div>
                <p className={styles.checkLabel}>Actifs</p>
              </div>
            </li>
            <li>
              <div className={styles.checkBoxItem}>
                <input
                  type="radio"
                  name="active"
                  value="inactives"
                  checked={activeState === "inactives"}
                  onChange={() => {
                    setActiveState("inactives");
                  }}
                />
                <div className={styles.checkmark}>
                  <Check className={styles.check_icon} />
                </div>
                <p className={styles.checkLabel}>Inactifs</p>
              </div>
            </li>
            <li>
              <div className={styles.checkBoxItem}>
                <input
                  type="radio"
                  name="active"
                  value="pending"
                  checked={activeState === "pending"}
                  onChange={() => {
                    setActiveState("pending");
                  }}
                />
                <div className={styles.checkmark}>
                  <Check className={styles.check_icon} />
                </div>
                <p className={styles.checkLabel}>En attente de validation</p>
              </div>
            </li>
          </ul>
        </div>
      );
    }
  }, [filterStatus, activeState]);

  const renderMore = useMemo(() => {
    if (!showMore) return null;
    return (
      <div onClick={handleMore} className={styles.more}>
        <Button bgColor="grey300" textColor="purple">
          Charger plus de concert
        </Button>
      </div>
    );
  }, [events]);

  const renderDateFilter = () => {
    if (startDate === null || endDate === null) {
      return "Date de l'événement";
    } else {
      const formattedStartDate = moment(startDate).format("DD/MM/YYYY");
      const formattedEndDate = moment(endDate).format("DD/MM/YYYY");
      return `${formattedStartDate} au ${formattedEndDate}`;
    }
  };

  const renderDateCreatedFilter = () => {
    if (createdStartDate === null || createdEndDate === null) {
      return "Date de création";
    } else {
      const formattedStartDate = moment(createdStartDate).format("DD/MM/YYYY");
      const formattedEndDate = moment(createdEndDate).format("DD/MM/YYYY");
      return `${formattedStartDate} au ${formattedEndDate}`;
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <h1 className={styles.title}>Liste des concerts</h1>
      </div>
      <div className={styles.head}>
        <div className={styles.filter}>
          <div className={styles.filterLeft}>
            <div ref={filterRef}>
              <button
                type={"button"}
                className={styles.filterButton}
                onClick={openFilter}
              >
                Filtrer par
                <ArrowDown />
              </button>
              {filterOpen && (
                <div className={styles.filterBox}>
                  <div className={styles.filterFirst}>
                    <ul>
                      <li>
                        <button
                          className={
                            filterStatus === "active" ? styles.active : ""
                          }
                          onClick={() => selectStatus("active")}
                        >
                          <span className={styles.filter_icon}>
                            <ToggleIcon />
                          </span>
                          Statut
                          <ArrowRight className={styles.arrow_right} />
                        </button>
                      </li>
                    </ul>
                  </div>
                  {renderFilters}
                </div>
              )}
            </div>
            <div className={styles.dateFilter} ref={dateFilterRef}>
              <button
                type={"button"}
                className={styles.dateFilterButton}
                style={
                  startDate !== null && endDate !== null
                    ? { paddingRight: "39px" }
                    : {}
                }
                onClick={openDateFilter}
              >
                <Calendar />
                {renderDateFilter()}
              </button>
              {startDate !== null && endDate !== null && (
                <button
                  className={styles.resetDateFilter}
                  onClick={resetDateFilter}
                >
                  <XCircle weight="fill" />
                </button>
              )}
              {dateFilterOpen && (
                <div className={styles.dateFilterBox}>
                  <AllEventsDatepicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setDateFilterOpen={setDateFilterOpen}
                    setPage={setPage}
                  />
                </div>
              )}
            </div>
            <div className={styles.dateFilter} ref={dateCreatedFilterRef}>
              <button
                type={"button"}
                className={styles.dateFilterButton}
                style={
                  createdStartDate !== null && createdEndDate !== null
                    ? { paddingRight: "39px" }
                    : {}
                }
                onClick={openDateCreatedFilter}
              >
                <Calendar />
                {renderDateCreatedFilter()}
              </button>
              {createdStartDate !== null && createdEndDate !== null && (
                <button
                  className={styles.resetDateFilter}
                  onClick={resetDateCreatedFilter}
                >
                  <XCircle weight="fill" />
                </button>
              )}
              {dateCreatedFilterOpen && (
                <div className={styles.dateCreatedFilterBox}>
                  <AllEventsDatepicker
                    startDate={createdStartDate}
                    setStartDate={setCreatedStartDate}
                    endDate={createdEndDate}
                    setEndDate={setCreatedEndDate}
                    setDateFilterOpen={setDateCreatedFilterOpen}
                    setPage={setPage}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.search}>
            <input
              onChange={getSearch}
              type={"text"}
              placeholder={"Rechercher"}
            />
            {loadingSearch ? (
              <div className={styles.searchLoading}>
                <Loading color={"green"} />
              </div>
            ) : (
              <Search />
            )}
          </div>
          <div className={styles.add}>
            <Link to="/evenements/ajouter">
              <Plus className={styles.add__icon} />
              Ajouter
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.list}>
        {loading && (
          <div className={styles.loading}>
            <Loading rounded={true} />
          </div>
        )}
        {renderEvents}
      </div>
      {renderMore}
    </div>
  );
};

export default AllEvents;

import Loading from "app/components/loading/Loading";
import Media from "app/components/media/Media";
import { handleCreateMedia } from "app/controllers/media";
import { MediaType } from "app/types/media";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import Camera from "ui/icons/camera/Camera";
import Image from "ui/icons/image/Image";
import styles from "./MediaForm.module.scss";

type Props = {
  mediasFiles?: any;
  addMediasFiles?: Function;
  deleteMediasFiles?: Function;
  typeFile?: string;
  typeForm?: string;
  edit?: boolean;
  id?: number;
};

const MediaForm = ({
  mediasFiles,
  addMediasFiles,
  deleteMediasFiles,
  typeFile,
}: Props) => {
  const { watch } = useFormContext();

  const [media, setMedia] = useState<MediaType>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [medias, setMedias] = useState([]);
  const [mediaErrors, setMediaErrors] = useState({
    size: false,
    type: false,
  });
  const { append, remove } = useFieldArray({
    name: "medias", // unique name for your Field Array
  });

  useEffect(() => {
    watch((value, { name, type }) => {
      setMedias(value.medias);
    });
  }, [watch]);

  useEffect(() => {
    if (media) {
      setLoading(true);
      handleCreateMedia(media)
        .then(function (response: any) {
          setTimeout(() => {
            setMedias([...medias, response.data]);
            setLoading(false);
            append({ media_id: response.data.id });

            addMediasFiles(response.data);
          }, 1000);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [media]);

  function deleteMedia(mediaId) {
    const index = medias.findIndex((x) => x.media_id === mediaId);
    remove(index);
    setMedias(medias.filter((x) => x.id !== mediaId));
    deleteMediasFiles(mediaId);
  }

  function uploadMedia(event) {
    setLoading(false);
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    const size = files[0].size;
    const type = files[0].type;

    if (
      size > 2000000 ||
      (type != "image/png" && type != "image/jpg" && type != "image/jpeg")
    ) {
      setMediaErrors((mediaErrors) => ({
        ...mediaErrors,
        size: size > 2000000,
        type:
          type != "image/png" && type != "image/jpg" && type != "image/jpeg",
      }));
      setLoading(false);
    } else {
      setMediaErrors({
        size: false,
        type: false,
      });
      reader.onload = (e) => {
        let updatedValue = {
          name: files[0].name,
          type: typeFile,
          format: [
            { size: "1920x1080" },
            { size: "205x110" },
            { size: "366x162" },
          ],
          subtype: "images",
          file_type: "jpg",
          fileBase64: e.target.result,
        };

        setMedia((media) => ({
          ...media,
          ...updatedValue,
        }));
      };
    }
  }

  const renderMedias = useMemo(() => {
    if (medias && medias.length == 0) return null;

    return mediasFiles.map((media, index) => {
      return (
        <li key={index}>
          <Media
            index={index}
            media={media}
            sizes={"205x110"}
            onDelete={deleteMedia}
            type={"medias"}
          />
        </li>
      );
    });
  }, [medias, loading]);

  const renderLoading = useMemo(() => {
    if (!loading) return null;
    return (
      <li>
        <p className={styles.loading}>
          <Loading color={"green"} />
        </p>
      </li>
    );
  }, [loading, media]);

  return (
    <div className={styles.main}>
      <div className={styles.head}>
        <p className={styles.title}>Médias</p>
      </div>
      <p className={styles.subtitle}>
        <Image className={styles.subtitle_icon} />
        Photos
      </p>
      <ul>
        {renderMedias}
        {renderLoading}

        <li>
          <button disabled={loading} className={styles.add} type={"button"}>
            <input onChange={uploadMedia} type={"file"} />
            <Image />
            <p>Ajouter une photo</p>
          </button>
        </li>
      </ul>

      <p className={styles.formats}>
        <b>Formats autorisés :</b> PNG, JPG - <b>Poids max :</b> 2mo
        {mediaErrors.size && (
          <p className={styles.errors}>
            Le poids de l’image doit être inférieur à 2 Mo
          </p>
        )}
        {mediaErrors.type && (
          <p className={styles.errors}>L'image doit être un JPG ou PNG</p>
        )}
      </p>
    </div>
  );
};

export default MediaForm;

import HourForm from "form/components/HourForm/HourForm";
import SubtitleForm from "form/components/subtitleForm/SubtitleForm";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import ArrowRightComplete from "ui/icons/arrow-right-complete/ArrowRightComplete";
import Check from "ui/icons/check/Check";
import Clock from "ui/icons/clock/Clock";
import Minus from "ui/icons/minus/Minus";

import styles from "./EventHour.module.scss";

const EventHour = () => {
  const { register, getValues, setValue } = useFormContext();

  const [cutHour, setCutHour] = useState(false);

  const values = getValues("event_hours");

  useEffect(() => {
    if (values) {
      const hours = values
        ? values
        : { am_start: "", am_end: "", pm_start: "", pm_end: "" };

      if (hours.pm_end != "" || hours.pm_start != "") {
        setCutHour(true);
      } else {
        setCutHour(false);
      }
    }
  }, [values]);

  function ClickCutHour(e) {
    setCutHour(e.target.checked);
    if (!e.target.checked) {
      setValue(`event_hours`, {
        am_start: values.am_start,
        am_end: values.am_end,
        pm_start: "",
        pm_end: "",
        is_closed: false,
      });
    }
  }

  const renderSeparation = useMemo(() => {
    if (cutHour) {
      return <Minus className={styles.minus_icon} />;
    } else {
      return <ArrowRightComplete className={styles.arrow_icon} />;
    }
  }, [cutHour]);

  return (
    <>
      <SubtitleForm text={"Horaires du concert"} icon={<Clock />} />
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <input
            {...register(`event_hours.am_start`)}
            type="time"
            className={styles.input}
          />
          <ArrowRightComplete className={styles.arrow_icon} />
          <input
            {...register(`event_hours.am_end`)}
            type="time"
            className={styles.input}
          />

          {cutHour && (
            <>
              {renderSeparation}
              <input
                {...register(`event_hours.pm_start`)}
                type="time"
                className={styles.input}
              />
              <ArrowRightComplete className={styles.arrow_icon} />
              <input
                {...register(`event_hours.pm_end`)}
                type="time"
                className={styles.input}
              />
            </>
          )}
        </div>

        <div className={styles.checkboxs}>
          <div className={styles.checkboxsItem}>
            <input
              checked={cutHour}
              type={"checkbox"}
              onChange={ClickCutHour}
            />
            <div className={styles.checkmark}>
              <Check className={styles.check_icon} />
            </div>
            <p className={styles.checkLabel}>Séparer en deux créneaux</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventHour;

import SubtitleForm from "form/components/subtitleForm/SubtitleForm";
import { useFormContext } from "react-hook-form";
import Label from "ui/components/label/Label";
import TextAlign from "ui/icons/text-align/TextAlign";

const EventDescription = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <SubtitleForm text={"Description"} icon={<TextAlign />} />
      <div>
        <Label htmlFor="description" isRequired={false}>
          Description du concert
        </Label>
        <textarea
          className={
            errors.description
              ? "m-input m-input--red m-input--textarea"
              : "m-input m-input--textarea"
          }
          name="description"
          {...register("description")}
        />
        {errors.description && (
          <p className={"m-error"}>Une description est requise</p>
        )}
      </div>
    </>
  );
};

export default EventDescription;

import TextAlign from "ui/icons/text-align/TextAlign";

import styles from "./EventSingleDescription.module.scss";

type Props = {
  description: string;
};

const EventSingleDescription = ({ description }: Props) => {
  return (
    <>
      <div className={styles.subtitle}>
        <TextAlign className={styles.subtitle_icon} />
        <p>Description du concert</p>
      </div>
      <div className={styles.text}>{description}</div>
    </>
  );
};

export default EventSingleDescription;

import { useMemo, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { showModal } from "app/actions/modal";
import EventBar from "events/components/eventForm/eventBar/EventBar";
import EventCharacteristic from "events/components/eventForm/eventCharacteristic/EventCharacteristic";
import EventDescription from "events/components/eventForm/eventDescription/EventDescription";
import EventGroup from "events/components/eventForm/eventGroup/EventGroup";
import EventHour from "events/components/eventForm/eventHour/EventHour";
import StylesForm from "form/components/stylesForm/StylesForm";
import EventInformation from "events/components/eventForm/eventInformation/EventInformation";
import { handleCreateEvent } from "events/controllers";
import HeaderForm from "form/components/headerForm/HeaderForm";
import MediaForm from "form/components/mediaForm/MediaForm";

import Button from "ui/components/button/Button";
import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";

import styles from "./CreateEvents.module.scss";
import Loading from "app/components/loading/Loading";
import { errorsAPI } from "app/constants/errors";

const CreateEvent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [medias, setMedias] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, setGroups] = useState([]);
  const [styleError, setStyleError] = useState(false);
  const methods = useForm({
    defaultValues: useMemo(() => {
      return {
        medias: [],
        styles: [],
        event_date: "",
        event_hours: {
          am_start: "",
          am_end: "",
          pm_start: "",
          pm_end: "",
          is_closed: false,
        },
        groups: [],
      };
    }, []),
  });
  const { handleSubmit, setValue, register, getValues } = methods;

  const addMedia = (data) => {
    setMedias([...medias, data]);
  };

  const removeMedia = (mediaId) => {
    setMedias(medias.filter((x) => x.id !== mediaId));
  };

  const onSubmit = (data) => {
    if (data.styles.length === 0) {
      setStyleError(true);
      return;
    } else {
      setStyleError(false);
    }
    setLoading(true);
    handleCreateEvent(data)
      .then(function (response: any) {
        const data = {
          status: true,
          message: "Evènement créé avec succes",
          error: false,
        };

        setTimeout(() => {
          setLoading(false);
          dispatch(showModal(data));
          navigate("/evenements");
        }, 2000);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
        setLoading(false);
      });
  };

  return (
    <div className={styles.main}>
      <Link to="/evenements" className={styles.back_link}>
        <ArrowLeft className={styles.back_icon} />
        Annuler
      </Link>
      <FormProvider {...methods}>
        <HeaderForm title={"Créer un concert"} label={"Evènement actif"} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.section}>
            <div className={styles.head}>
              <p className={styles.title}>Détails du concert</p>
            </div>
            <div className={styles.information}>
              <EventInformation />
            </div>
            <div className={styles.hours}>
              <EventHour />
            </div>
            <div className={styles.description}>
              <EventDescription />
            </div>
            <div className={styles.description}>
              <EventCharacteristic />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.styles}>
              <StylesForm required={true} />
            </div>
          </div>
          <div className={styles.section}>
            <MediaForm
              mediasFiles={medias}
              addMediasFiles={addMedia}
              deleteMediasFiles={removeMedia}
              typeFile={"events"}
            />
          </div>

          <div className={styles.sectionempty}>
            <div className={styles.head}>
              <p className={styles.title}>Groupe(s)</p>
            </div>
            <EventGroup groupsEdit={[]} />
          </div>

          <div className={styles.sectionempty}>
            <div className={styles.head}>
              <p className={styles.title}>
                Bar <span>*</span>
              </p>
            </div>
            <EventBar />
          </div>

          <div className={styles.bottom}>
            <Button
              type={"submit"}
              bgColor="green"
              textColor="purple"
              className={styles.save_button}
            >
              {loading ? <Loading /> : "Enregistrer les modifications"}
            </Button>
          </div>
          {styleError && (
            <p className={styles.error}>Veuillez choisir au moins un style</p>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateEvent;

import { Link, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";

import { handleCreateBar } from "bars/controllers";
import { showModal } from "app/actions/modal";

import ArrowLeft from "ui/icons/arrow-left/ArrowLeft";
import HeaderBar from "bars/components/bar/headerBar/HeaderBar";
import InformationsBar from "bars/components/bar/informationsBar/InformationBar";
import DescriptionBar from "bars/components/bar/descriptionBar/DescriptionBar";
import HoursBar from "bars/components/bar/hoursBar/HoursBar";
import CharacteristicsBar from "bars/components/bar/characteristicsBar/CharacteristicsBar";

import Button from "ui/components/button/Button";
import StylesForm from "form/components/stylesForm/StylesForm";

import styles from "./CreateBar.module.scss";
import Loading from "app/components/loading/Loading";
import MediaForm from "form/components/mediaForm/MediaForm";
import DocumentForm from "form/components/documentForm/DocumentForm";
import VideoForm from "form/components/videoForm/VideoForm";
import { errorsAPI } from "app/constants/errors";

const CreateBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [documents, setDocuments] = useState([]);
  const [medias, setMedias] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const methods = useForm({
    defaultValues: useMemo(() => {
      return {
        active: false,
        dont_want_contact: true,
        styles: [],
        medias: [],
        documents: [],
        videos: [],
        negociation_open: false,
        open_hours: [
          {
            monday: {
              am_start: "",
              am_end: "",
              pm_start: "",
              pm_end: "",
              is_closed: false,
            },
            tuesday: {
              am_start: "",
              am_end: "",
              pm_start: "",
              pm_end: "",
              is_closed: false,
            },
            wednesday: {
              am_start: "",
              am_end: "",
              pm_start: "",
              pm_end: "",
              is_closed: false,
            },
            thursday: {
              am_start: "",
              am_end: "",
              pm_start: "",
              pm_end: "",
              is_closed: false,
            },
            friday: {
              am_start: "",
              am_end: "",
              pm_start: "",
              pm_end: "",
              is_closed: false,
            },
            saturday: {
              am_start: "",
              am_end: "",
              pm_start: "",
              pm_end: "",
              is_closed: false,
            },
            sunday: {
              am_start: "",
              am_end: "",
              pm_start: "",
              pm_end: "",
              is_closed: false,
            },
          },
        ],
        geolocation: null,
      };
    }, []),
  });

  const { handleSubmit } = methods;

  const addDocument = (data) => {
    setDocuments([...documents, data]);
  };

  const removeDocument = (documentId) => {
    setDocuments(documents.filter((x) => x.id !== documentId));
  };

  const addMedia = (data) => {
    setMedias([...medias, data]);
  };

  const removeMedia = (mediaId) => {
    setMedias(medias.filter((x) => x.id !== mediaId));
  };

  const onSubmit = (data) => {
    setLoading(true);
    handleCreateBar(data)
      .then(function (response: any) {
        const data = {
          status: true,
          message: "Bar créé avec succes",
          error: false,
        };

        setTimeout(() => {
          dispatch(showModal(data));
          setLoading(false);
          navigate("/bars-et-lieux");
        }, 2000);
      })
      .catch((err) => {
        const data = {
          status: true,
          message: errorsAPI[err.response.data.message],
          error: true,
        };
        dispatch(showModal(data));
        setLoading(false);
      });
  };
  return (
    <div className={styles.main}>
      <Link to="/bars-et-lieux" className={styles.back_link}>
        <ArrowLeft className={styles.back_icon} />
        Annuler
      </Link>
      <FormProvider {...methods}>
        <HeaderBar title={"Créer un lieu"} />
        <form id="createbar" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.section}>
            <div className={styles.information}>
              <InformationsBar />
            </div>
            <div className={styles.description}>
              <DescriptionBar />
            </div>
            <div className={styles.hours}>
              <HoursBar />
            </div>
            <div className={styles.characteristics}>
              <CharacteristicsBar />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.styles}>
              <StylesForm />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.media}>
              <MediaForm
                mediasFiles={medias}
                addMediasFiles={addMedia}
                deleteMediasFiles={removeMedia}
                typeFile={"bars"}
              />
              <VideoForm />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.media}>
              <DocumentForm
                documentsFiles={documents}
                addDocumentsFiles={addDocument}
                deleteDocumentsFiles={removeDocument}
                typeFile={"bars"}
              />
            </div>
          </div>
          <div className={styles.bottom}>
            <Button
              disabled={loading}
              type={"submit"}
              bgColor="green"
              textColor="purple"
              className={styles.save_button}
            >
              {loading ? <Loading /> : "Créer le lieu"}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
export default CreateBar;
